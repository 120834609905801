.searchInput {color: black;}
.searchInput:focus::placeholder {
  color: #cccccc;
  transition: all 0.8s;
}

.focus\:sm:w-[25rem]:focus {
  width: 45rem !important;
  transition: all 0.8s !important;
}


.expand {
  width: 25rem ;
  min-width:25rem ;
}
.expand:focus {
  width: 45rem !important;
  min-width: 45rem !important;
  transition: 0.8s 0.8s !important;
}