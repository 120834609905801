.iconButton {
  @apply font-secondary text-[0.875rem] h-10 cursor-pointer;
  line-height: 21px;
  border-radius: 20px;
}

.button--primary {
  @apply bg-base font-bold !important;
  color: white !important;
}

.button--primary:hover {
  @apply bg-resolutionBlue !important;
}

.button--primary:focus {
  @apply bg-base;
  box-shadow: 0 0 2px 2px rgba(0, 108, 255, 0.9);
}

.button--primary:active {
  @apply bg-resolutionBlue opacity-80;
}

.button--primary:disabled,
.button--primary[disabled] {
  @apply bg-base opacity-40 !important;
}

.button--secondary {
  @apply bg-white font-medium border border-solid border-mist !important;
  color: darkgray !important;
}

.button--secondary:hover {
  @apply bg-clouds !important;
}

.button--secondary:focus {
  @apply bg-white;
  box-shadow: 0 0 2px 2px rgba(0, 108, 255, 0.9);
}

.button--secondary:active {
  @apply bg-lightGrey opacity-80;
}

.button--secondary:disabled,
.button--secondary[disabled] {
  @apply bg-white opacity-40 !important;
}

.button--highlighted {
  @apply font-bold border border-solid border-turquoise !important;
  color: navy !important;
  background-color: #29ef97;
}

.button--highlighted:hover {
  @apply bg-artyClickOceanGreen !important;
}

.button--highlighted:focus {
  @apply border-0;
  box-shadow: 0 0 2px 2px rgba(0, 108, 255, 0.9);
  background-color: #29ef97;
}

.button--highlighted:active {
  @apply bg-artyClickOceanGreen opacity-80;
}

.button--highlighted:disabled,
.button--highlighted[disabled] {
  @apply opacity-40 !important;
  background-color: #29ef97 !important;
}

.button--icontopic {
  @apply bg-white text-textdark font-medium border-2 border-solid border-white opacity-90 !important;
}

.button--icontopic:hover {
  @apply border-2 border-solid border-turquoise !important;
}

.button--icontopic:focus {
  @apply border-0 !important;
  box-shadow: 0 0 2px 2px rgba(0, 108, 255, 0.9);
}

.button--icontopic:active {
  @apply bg-white opacity-80;
}
