.menuItem {
  color: white;
  font-family: poppins;
  font-size: 0.875rem;
  cursor: pointer;
  font-weight: bold;
}

.accountName {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;
  color: #011d58;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  background-color: #29ef97;
  border-radius: 18px;
}
